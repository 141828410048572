///////////// User Profile /////////////

.user-profile-wrapper {
  margin-top: 4rem;
  max-height: 90vh;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }

  .header-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #3f3f3f;
  }

  .btn-upload {
    background-color: transparent;
    border-radius: 0.5rem;
    padding: 0.7rem 1.3rem;
    cursor: pointer;
    width: 7.125rem;
    height: 3.063rem;
    margin: 0.5rem 0;
    display: block;
    border: solid 1px #f36b7f;
    text-align: center;
  }
  span {
    vertical-align: -webkit-baseline-middle;
    margin-left: 1rem;
    color: #3f3f3f;
  }
  .btn-connect {
    border-radius: 1rem;
    background-color: transparent;
    color: #34b2c3;
  }
  .my-bio {
    textarea {
      margin-top: 0;
    }
  }
}

//************** Whole application checkbox css ********************

input[type='checkbox'] {
  appearance: none;
}

input[type='checkbox']::before {
  content: '';
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 8px;
  border: 1px solid #bbbbbb;
}

input[type='checkbox']:checked::before {
  content: '';
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 8px;
  border: 1px solid #bbbbbb;
  background-color: white;
  background-image: url(https://homh-site-media.s3.amazonaws.com/custom-uploads/tick.svg);
  background-repeat: no-repeat;
  background-size: 1rem 1.4rem;
  background-position: center;
}
