.listing-wrapper {
  // max-height: 86vh;
  overflow-x: hidden;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}

.event-card {
  margin: 0 auto;
}
.no-event-wrapper {
  .tab-btn-login {
    margin: 0 auto;
    float: none !important;
    width: 8.063rem !important;
    height: 3.375rem !important;
    background-color: #f36b7f;
    display: block !important;
    border-radius: 2rem !important;
  }
}
.card-wrapper {
  .btn-loadmore {
    margin: 0 auto;
    float: none !important;
    margin-bottom: 2rem;
    background-color: #f36b7f;
  }
}

.button_disable {
  cursor: not-allowed;
  opacity: 0.8;
  &:disabled {
    background-color: #d2d2d2;
  }
}
.position-relative {
  position: relative !important;
}
/////////////////// Event Detail Popup /////////////////
.notes-description-wrapper {
  overflow-y: scroll;
  padding-bottom: 6rem;

  &::-webkit-scrollbar {
    display: none;
  }

  .event-title {
    font-size: 1.563rem;
    font-weight: bold;
    color: #3f3f3f;
  }
  .after-rsvp-title {
    font-size: 1.063rem;
    font-weight: bold;
    color: #3f3f3f;
  }
  .about-event-title {
    font-size: 1.25rem;
    font-weight: bold;
    color: #3f3f3f;
  }
  .about-sub-header {
    font-size: 1rem;
    color: #3f3f3f;
  }

  .first-child {
    height: auto;
    margin: 0 3rem;
  }

  .event-description {
    border-bottom: 0.063rem solid #f3f3f3;
    margin: 0 3rem;
    padding: 3rem 0;
    img {
      flex-grow: 0;
      border-radius: 3.938rem;
    }
    &:last-child {
      border-bottom: none;
    }
  }

  .event-info {
    padding: 1rem 0;
    span {
      color: #5d5d5d;
    }
  }
  .btn-iwantrsvp {
    float: left !important;
    background-color: #f36b7f;
  }
  .btn-unrsvp {
    padding: 2rem;
    background-color: transparent;
    color: #a9a9a9;
    text-decoration: underline;
  }
  .btn-view-event {
    padding: 1rem 0;
    margin: 1rem 3rem;
  }
  .view-notes-heading {
    font-size: 1.563rem;
    font-weight: bold;
    color: #3f3f3f;
  }
  .view-event-link {
    float: left !important;
    color: #f36b7f;
    font-size: 1.125rem;
    font-weight: bold;
  }
  .admin-notes-text {
    font-size: 1.125rem;
    font-weight: 500;
    color: #3f3f3f;
  }
  .admin-notes-section {
    height: auto;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    margin: 0 3rem;
    .notes-tab {
      &:first-child {
        margin-left: 0;
      }
    }
  }
  .notes-title {
    font-size: 0.938rem;
    color: #7d7d7d;
  }
}

/////////////////// Event Rating //////////////////
.rating-textarea {
  height: 14rem !important;
}
.btn-disable {
  pointer-events: none;
  background-color: #c2c2c2 !important;
}

///////////////// Calendar //////////////////
.date-selected {
  background: #f36b7f !important;
  color: #ffffff !important;
  .event-dot {
    background-color: #ffffff !important;
  }
}
.date-hover {
  &:hover {
    @extend .date-selected;
  }
}

////////////////// Un-rsvp modal //////////////
.unrsvp-wrapper {
  .btn-no {
    background-color: #f36b7f;
  }
  .close-btn-icon {
    top: 2rem;
    right: 2rem;
  }
}

///////////////// Tab Class //////////////////
.tab-icon {
  width: 3rem !important;
  height: 3rem;
  position: relative;
  bottom: 0.5rem;
  padding-top: 0 !important;
}

/////////////// Event Description //////////////
.mobile-only {
  background-color: #d62929;
  margin-top: 3.8rem;
}
.description-wrapper {
  max-height: 95vh;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  .event-tabs {
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  .noshow-message {
    color: #d62929;
  }
  .btn-live-stream {
    height: 3.375rem;
  }
  .time {
    padding-left: 1.1rem;
  }
}

.noshow-popup {
  max-width: 33.438rem;
  .close-btn-icon {
    top: 2rem;
    right: 2rem;
  }
}

input[type='checkbox']::before {
  content: '';
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 1rem;
  border: 0.125rem solid #5d5d5d;
}

input[type='checkbox']:checked::before {
  content: '';
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 1rem;
  border: 0.063rem solid #34b2c3;
  background-color: #34b2c3;
  background-image: url(https://assets.hom.events/hom-planner-theme/ConsumerApp/RSVP_tick.svg);
  background-repeat: no-repeat;
  background-size: 0.8rem;
  background-position: center;
}

////////////////////////// TASK LIST ////////////////////////
.task-list {
  min-height: 12rem;
  padding: 1rem;
  border-radius: 1.5rem;
  border: solid 0.063rem #f3f3f3;
  background-color: #fafafa;
  &:last-child {
    margin-bottom: 4rem;
  }
}

.my-tasks {
  width: 6.5rem;
  height: 3rem;
  margin: 0 0 0 0.833rem;
  padding: 0.625rem 1.25rem 1.188rem 1.25rem;
  border-radius: 40px;
  background-color: #616161;
  color: #ffffff !important;
}

/////////////////////////// CALL LIST //////////////////////////
.call-list {
  height: 9.688rem;
  padding: 1rem;
  border-radius: 1.5rem;
  border: solid 0.063rem #f3f3f3;
  background-color: #fafafa;
  &:last-child {
    margin-bottom: 3rem;
  }
}
.margin-auto {
  margin: 0 auto;
}

//////////////////////// Inventory /////////////////////////
.invlist {
  ul {
    list-style: initial;
  }
}

////////////////////// Event Action Dropdown /////////////////////////
.eventaction-wrapper {
  width: 16.875rem;

  .select_field__control {
    width: 16.95rem;
    border-radius: 2rem !important;
    border: transparent !important;
    background: transparent !important;
  }

  .select_field__menu,
  .select_field__menu-list {
    width: 16.875rem;
    border-radius: 0.7rem !important;
  }
  .select_field__option {
    padding-top: 1rem;
  }
  .select_field__option--is-focused {
    background-color: #ffe2de;
    padding-top: 1rem;
  }
  .select_field__value-container {
    opacity: 0;
  }
  .select_field__option--is-selected {
    background-color: #ffe2de;
  }
}

.btn-event-action {
  position: relative;
  left: 16.88rem;
  top: 0.25rem;
  z-index: 9;
}

//////////////////////// Send Broadcast ////////////////////
.broadcast-section {
  .broadcast-title {
    font-size: 1.563rem;
    color: #3f3f3f;
  }
  .broadcast-textarea {
    height: 14.063rem;
    &::placeholder {
      color: #c2c2c2;
    }
  }
  .broadcast-button {
    height: 3.375rem;
    border-radius: 2rem;
  }
}

//////////////// Notes Section /////////////
.floor-img {
  max-height: 37.313rem;
  max-width: 39.125rem;
  img {
    max-height: 30.313rem;
    max-width: 39.125rem;
    width: 100%;
  }
}

//////////////// Send Sub request /////////////
.sub-request-wrapper {
  max-height: 90vh;
  overflow-y: scroll;
  padding-bottom: 3rem;
  &::-webkit-scrollbar {
    display: none;
  }
}

/////////// timezone ////////////
.timezone_action__control {
  width: 14rem !important;
  border-radius: 2rem !important;
  border: transparent !important;
  background: transparent !important;
  box-shadow: none !important;
}
.timezone_action__option {
  padding-top: 1rem;
}
.timezone_action__option--is-focused {
  background-color: #ffe2de !important;
  padding-top: 1rem;
  box-shadow: none !important;
}
.timezone_action__option--is-selected {
  background-color: #ffe2de !important;
  color: #5d5d5d !important;
}
.timezone_action__indicators {
  padding-right: 2rem !important;
}
.timezone_action__menu {
  width: 14rem !important;
}
.timezone_list {
  overflow-y: auto;
  max-height: 80vh;
  &::-webkit-scrollbar {
    display: none;
  }
}

.vendor-notes {
  width: 100%;
  height: auto;
  flex-grow: 0;
  margin: 1rem 0;
  padding: 0.875rem 1.625rem 1.063rem 1.313rem;
  border-radius: 5px;
  border: solid 1px #eee;
  background-color: #f6f6f6;
}
