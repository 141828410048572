.modal-overlay {
  z-index: 201;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(67, 67, 67, 0.7);
}
.modal-body {
  width: 39.813rem;
  min-height: 15.375rem;
  border-radius: 1.25rem;
  background-color: #ffffff;
  padding: 2.935rem 3.375rem;
  margin: 0 auto;
  transform: translateY(50%);
  &:focus {
    outline: none;
  }
}
.btn-close {
  position: absolute;
  top: 3rem;
  right: 3rem;
  &:focus {
    outline: none;
  }
}
