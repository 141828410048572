.login_wrapper {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    display: none;
  }

  .leftside {
    img {
      position: relative;
      right: 10rem;
      top: 4rem;
    }
  }
  .rightside {
    padding-top: 6rem;
    width: 19.4rem;
    height: 50px;
    margin: 0 auto;

    .header_text {
      font-size: 1.5rem;
    }

    .field_label {
      font-size: 1rem;
    }

    .login_forgot {
      float: right;
      color: #529092;
      font-size: 1rem;
      cursor: pointer;
    }

    .login_btn {
      width: 19.4rem;
      border-radius: 0.5rem;
      display: block;
      background-color: #f36b7f;
      border-radius: 2.5rem;
      &:disabled {
        cursor: not-allowed;
        opacity: 0.8;
        background-color: #d2d2d2;
      }
    }
  }
}

.forgot_heading {
  font-size: 1.438rem !important;
}

.event-assigned-message {
  width: 16.625rem;
  height: 9.375rem;
}
